import React from "react"
import PageTransition from 'gatsby-plugin-page-transitions';
import Layout from "../components/layout"
import VideoCover from "../components/video-cover"
import Solutions from "../components/solutions"
import SEO from "../components/seo"

class OurSolutions extends React.Component {
  render = () => (
    
      <Layout>
        <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
          <main className="scroll-jacker">
            
            <VideoCover
              title="Nous faisons de la magie."
              videoUrl="/video/positiveemotiondev.mp4" 
            />

            <h2 
              className="belt" 
              style={{ fontFamily: "inherit" }}
            >
              Chef-d'œuvre Digital
            </h2>

            <main className="manifest">
              <section className="manifest__section manifest__section--services" style={{
                padding: "0"
              }}>
                <header className="manifest__services-header manifest__services-header--toggled"></header>
                <article className="manifest__article">
                  <h2 className="manifest__article-heading">Chef-d'œuvre Digital</h2>
                  <p className="manifest__para">
                    <br /><br />
                    Le mot "IMPOSSIBLE" n'existe pas dans le langage UTOPIQUE
                    <br /><br />
                  </p>
                </article>
              </section>
            </main>

          </main>

          <Solutions />
      </Layout>
    
  )

  componentDidMount = () => {
  }

}

export default OurSolutions
